import { SerializedStyles } from "@emotion/react";
import React, { ChangeEvent, MutableRefObject, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { vinDefault } from "./variants";
import { useClickAway } from "../../hooks";

export interface IProps {
  placeholderMessage: string;
  error: ReactNode;
  showLoader: boolean;
  testId?: string;
  inputName: string;
  variant?: { [x: string]: (...any: any) => SerializedStyles };
  handleFocus?: (boolean) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (phrase: string, ref: MutableRefObject<HTMLInputElement>) => void;
  autoFocus?: boolean;
  disableMaxWidth?: boolean;
  withSearchButtonText?: string;
  ariaLabelVinSearchButton: string;
}
export enum IconStatus {
  GLASS = "glassIcon",
  ARROW = "arrowIcon",
  JUMPING_ARROW = "jumpingArrow",
  SPINNER = "spinner",
}

export const VinSearch: React.FC<IProps> = ({
  placeholderMessage,
  error,
  showLoader,
  testId,
  inputName,
  handleFocus,
  onChange,
  onSubmit,
  variant = vinDefault,
  autoFocus = false,
  withSearchButtonText,
  ariaLabelVinSearchButton,
}) => {
  const vinInputRef = useRef<HTMLInputElement>(null);
  const [vinOrPlate, setVinOrPlate] = useState("");
  const [hasError, setHasError] = useState(false);
  const [[changeAnimation, shouldAnimate], setChangeAnimation] = useState([IconStatus.GLASS, false]);
  const stylesUsed = variant;
  const ref = useRef();

  const handleClose = () => {
    setChangeAnimation([IconStatus.GLASS, true]);
  };

  useEffect(() => {
    setHasError(Boolean(error));
  }, [error]);

  const animatedClass = useMemo(() => {
    switch (changeAnimation) {
      case IconStatus.GLASS:
        return stylesUsed.glassIcon(shouldAnimate);
      case IconStatus.ARROW:
        return stylesUsed.arrowIcon(shouldAnimate);
      case IconStatus.JUMPING_ARROW:
        return stylesUsed.jumpingArrow();
      case IconStatus.SPINNER:
        return stylesUsed.loadingSpinner();
      default:
        return null;
    }
  }, [changeAnimation]);

  const handleChangeIcon = () => {
    if (changeAnimation !== IconStatus.JUMPING_ARROW) {
      setChangeAnimation([IconStatus.ARROW, changeAnimation === IconStatus.GLASS]);
    }
  };

  useEffect(() => {
    let timeout;
    if (showLoader) {
      timeout = setTimeout(() => setChangeAnimation([IconStatus.SPINNER, true]), 400);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showLoader]);

  useClickAway(ref, handleClose, changeAnimation === IconStatus.GLASS);
  return (
    <div ref={ref}>
      <form
        css={stylesUsed.form(!!withSearchButtonText)}
        onFocus={handleChangeIcon}
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(vinOrPlate, vinInputRef);
        }}
      >
        <div css={stylesUsed.errorWrapper}>{error}</div>
        <input
          css={stylesUsed.input(hasError)}
          name={inputName}
          placeholder={placeholderMessage}
          ref={vinInputRef}
          onFocus={() => handleFocus && handleFocus(true)}
          onBlur={() => handleFocus && handleFocus(false)}
          onChange={(e) => {
            setVinOrPlate(e.target.value);
            onChange(e);
          }}
          autoFocus={autoFocus}
        />
        <button
          type="submit"
          data-testid={testId}
          css={stylesUsed.searchIconWrapper(!!withSearchButtonText, showLoader)}
          onClick={() => setChangeAnimation([IconStatus.JUMPING_ARROW, true])}
          disabled={hasError}
          aria-label={ariaLabelVinSearchButton}
        >
          {withSearchButtonText ? (
            <div>{withSearchButtonText}</div>
          ) : (
            <div css={stylesUsed.iconClipper(changeAnimation === IconStatus.JUMPING_ARROW)}>
              <div css={animatedClass}></div>
            </div>
          )}
        </button>
      </form>
    </div>
  );
};
