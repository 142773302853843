import { css } from "@emotion/react";
import { colors, spacing, mediaQuery, fontSizes, lineHeights, letterSpacings } from "../../../core/styles";
import { Icons } from "../../Icon";
import { keyframes } from "@emotion/css";

const sprite = keyframes`
    0% { transform: translateX(1px); }
    100% { transform: translateX(-192px); }
`;
const spriteBack = keyframes`
    0% { transform: translateX(-192px); }
    100% { transform: translateX(1px); }
`;
const moveArrow = keyframes`
    0% { transform: translateX(1px); }
    100% { transform: translateX(-574px); }
`;
const moveArrowBack = keyframes`
    0% { transform: translateX(-574px); }
    100% { transform: translateX(1px); }
`;
const rotate = keyframes`
    0% { transform: rotate(0deg); opacity: 1; }
    50% { transform: rotate(210deg); opacity: 0.6;  }
    80% { transform: rotate(80deg); }
    100% { transform: rotate(360deg); opacity: 1; }
`;
const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const glassIcon = (animate: boolean) =>
  css({
    content: `url(${Icons.animatedSearch})`,
    height: "24px",
    cursor: "pointer",
    transform: "translateX(1px)",
    animation: animate ? `${spriteBack} 200ms steps(8)` : "none",
    animationFillMode: "forwards",
    transition: `opacity 200ms 200ms`,
  });

export const arrowIcon = (animate: boolean) =>
  css({
    content: `url(${Icons.animatedSearch})`,
    height: "24px",
    cursor: "pointer",
    transform: "translateX(-192px)",
    animation: animate ? `${sprite} 200ms steps(8)` : "none",
    animationFillMode: "forwards",
    transition: `opacity 100ms 200ms`,
  });

export const jumpingArrow = () =>
  css({
    content: `url(${Icons.animatedArrowAfterClick})`,
    height: "24px",
    cursor: "pointer",
    animation: `${moveArrow} 300ms steps(12)`,
    animationFillMode: "forwards",
    transition: `opacity 100ms 200ms`,
    "&:active": {
      animation: `${moveArrowBack} 400ms steps(1) forwards`,
    },
  });

export const loadingSpinner = () =>
  css({
    height: "24px",
    position: "relative",
    top: `-4px`,
    left: "4px",
    animation: `${fadeIn} 100ms linear forwards`,
    opacity: 0,
    "&::after": {
      position: "absolute",
      content: `" "`,
      backgroundSize: "cover",
      width: "16px",
      height: "16px",
      top: spacing.baseUnitMobile1,
      right: spacing.baseUnitMobile1,
      backgroundImage: `url(${Icons.loadingIcon})`,
      animation: `${rotate} 1s linear infinite`,
    },
  });
export const iconClipper = (wider: boolean) =>
  css({
    width: wider ? "48px" : "24px",
    margin: wider ? "0 -2px" : "0 12px",
    height: "24px",
    overflow: "hidden",
    position: "relative",
  });

export const vinDefault = {
  searchIconWrapper: (withSearchButton: boolean, showLoader: boolean) =>
    withSearchButton
      ? css({
          fontWeight: 700,
          background: showLoader ? colors.lightGrey.hex : colors.linkBlue.hex,
          color: showLoader ? "transparent" : colors.white.hex,
          border: "none",
          borderRadius: "100px",
          letterSpacing: "1px",
          fontSize: fontSizes.body.cat,
          outline: "none",
          height: "40px",
          marginTop: spacing.baseUnitMobile3,
          width: "100%",
          padding: `11px ${spacing.baseUnitMobile3}`,
          position: "relative",
          "&::before": {
            visibility: showLoader ? "visible" : "hidden",
            content: `""`,
            position: "absolute",
            inset: "0",
            margin: "auto",
            width: "18px",
            height: "18px",
            backgroundColor: colors.white.hex,
            mask: `url(${Icons.loadingIcon}) no-repeat center`,
            maskSize: "cover",
            animation: `${rotate} 1s linear infinite`,
          },
          [mediaQuery.largerThanMobile]: {
            marginTop: 0,
            position: "absolute",
            width: "auto",
            minWidth: "127px",
            right: "4px",
            top: "4px",
          },
        })
      : css({
          background: "transparent",
          position: "absolute",
          right: "11px",
          margin: "0px",
          padding: "10px 0",
          outline: "none",
          WebkitTapHighlightColor: "transparent",
          width: "48px",
          border: `2px solid transparent`,
          "&:focus": {
            width: "68px",
            transform: "translateX(11px)",
            borderRadius: "0 24px 24px 0",
            paddingRight: "20px",
            border: `2px solid ${colors.linkBlue.hex}`,
            outline: "none",
            paddingLeft: "9px",
          },
        }),
  form: (disableMaxWidth: boolean) =>
    css({
      maxWidth: disableMaxWidth ? "auto" : "528px",
      margin: `${spacing.baseUnitMobile3} auto`,
      position: "relative",
    }),
  input: (hasError: boolean) =>
    css({
      textTransform: "uppercase",
      border: `1px solid`,
      borderColor: hasError ? colors.red.hex : "#B1B4B4",
      borderRadius: "24px",
      backgroundColor: colors.white.hex,
      padding: `${spacing.baseUnitMobile1} ${spacing.baseUnitMobile3}`,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: hasError ? colors.red.hex : colors.headlineGrey.hex,
      width: "100%",
      height: `${spacing.baseUnitMobile6}`,
      margin: "0 auto",
      transition: "all .3s ease",

      "::placeholder": {
        color: colors.stdTextGrey.hex,
        textTransform: "none",
        fontSize: fontSizes.body.mouse,
        lineHeight: lineHeights.body.mouse,
        letterSpacing: letterSpacings.body.mouse,
        [mediaQuery.tabletOrLarger]: {
          fontSize: fontSizes.body.horse,
          lineHeight: lineHeights.body.horse,
        },
      },
      "&:focus": {
        boxShadow: " 0 0 10px 0 rgba(0,0,0,0.3)",
        borderColor: hasError ? colors.red.hex : colors.linkBlue.hex,
        outline: 0,
      },
    }),
  errorWrapper: () =>
    css({
      width: `calc(100% - ${spacing.baseUnitMobile4})`,
      margin: "auto",
      position: "absolute",
      right: 0,
      left: 0,
    }),
  buttonSearch: () =>
    css({
      width: "50px",
      height: "50px",
      backgroundColor: "red",
      display: "block",
    }),
  iconClipper: iconClipper,
  jumpingArrow: jumpingArrow,
  glassIcon: glassIcon,
  arrowIcon: arrowIcon,
  loadingSpinner: loadingSpinner,
};
